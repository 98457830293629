import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { apiLink } from '../../../helpers/commons.js';

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from './actionTypes';
import { loginSuccess, logoutUserSuccess, apiError } from './actions';

import { postLogin } from '../../../helpers/AuthType/komandorBackend';

function* loginUser({ payload: { user, history } }) {
    try {

        const response = yield call(postLogin, apiLink('admin/login'), { email: user.email, password: user.password });
        yield put(loginSuccess(response));
        sessionStorage.setItem("authUser", JSON.stringify(response));

        history.push('/dashboard');
    } catch (error) {
        yield put(apiError(error));
    }
}

function* logoutUser({ payload: { history } }) {
    console.log('logout');
    try {
        var sessionData = JSON.parse(sessionStorage.getItem('authUser'));
        
        const response = yield call(postLogin, apiLink('admin/logout'), { token: sessionData.user_token });
        console.log(response);
        yield put(logoutUserSuccess(response));
        sessionStorage.removeItem("authUser");
        history.push('/login');
    } catch (error) {
        yield put(apiError(error));
    }
}


export function* watchUserLogin() {
    yield takeEvery(LOGIN_USER, loginUser)
}

export function* watchUserLogout() {
    yield takeEvery(LOGOUT_USER, logoutUser)
}

function* authSaga() {
    yield all([
        fork(watchUserLogin),
        fork(watchUserLogout),
    ]);
}

export default authSaga;