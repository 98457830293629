import axios from 'axios';

function getData (url, folder) {
    var sessionData = JSON.parse(sessionStorage.getItem('authUser'));

    return axios.post(url, { token: sessionData.user_token, folder: folder })
      .then((response) => {
            console.log(response);
            if (response.data.auth !== true) {
                throw("not authorized call get " + url)
            }
            else {
                  return response.data;
            }
        }, (error) => {
          console.log(error);
            throw("call error")
        });
}

function postData (url, data) {
    var sessionData = JSON.parse(sessionStorage.getItem('authUser'));

    return axios.post(url, { token: sessionData.user_token, data: data })
      .then((response) => {
            console.log(response);
            if (response.data.auth !== true) {
                throw("not authorized call")
            }
            else {
                  return response.data;
            }
        }, (error) => {
          console.log(error);
          throw("call error")
        });
}

export { getData, postData }