import React, {
    Component
} from "react";

import {
    Card,
    CardBody,
    CardTitle,
    Button,
    Modal,
    Label,
    Input,
    FormGroup,
    Row,
    Col,
    Progress
} from "reactstrap";

import {
    withRouter
} from "react-router-dom";
import {
    getData,
    postData
} from "../../helpers/requests.js";
import { apiLink } from '../../helpers/commons.js';
import Dropzone from "react-dropzone";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from 'axios';

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { logoutUser } from "../../store/auth/login/actions.js";

const folderType = {
    DOWNLOAD0: '0',
    DOWNLOAD1: '1',
    DOWNLOAD2: '2',
    UPLOAD0: 'uploads0',
    UPLOAD1: 'uploads1',
    UPLOAD2: 'uploads2'
}

class Materials extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            files: [],
            file: { name: '', uploaded: false, progress: 0, files: []},
            confirmDeletion: false,
            activeFolder: folderType.DOWNLOAD0
        }
        
        this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this);
        
        this.resetStates = this.resetStates.bind(this);
        this.initializeView = this.initializeView.bind(this);
        this.deleteFile = this.deleteFile.bind(this);
        this.changeFolder = this.changeFolder.bind(this);
    }

    resetStates() {
        this.setState({
            isLoading: true,
            files: [],
            file: { name: '', uploaded: false, progress: 0, files: []},
            confirmDeletion: false
        });
    }
    
    componentDidMount() {
        this.initializeView();
    }
    
    async initializeView(id = 0) {
        this.setState({ isLoading: true });
        this.resetStates();

        try {
            var response = await getData(apiLink('admin/getFiles'), id);
        } catch (error) {
            console.log(error);
            this.props.history.push('/logout');
            return;
        }
        response = response.data;
        
        let files = response;
        
        this.setState({
            files: files,
            isLoading: false
        });
    }

    handleAcceptedFiles = (files, texture) => {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: this.formatBytes(file.size)
            })
        );
        texture.files = files;
        this.setState({ texture });
        console.log(this.state);
        this.upload(texture);
        
    };

    handleRemovedFiles = texture => {
        texture.files = [];
        texture.id = null;
        this.setState(texture);
    }

    
    renderDropzone(texture, render, fileType) {
        if (!render) {
            return null;
        }
        return(
            <div className="col-lg-12 float-left p-2">
            <div className="col-lg-12 float-left border rounded p-4 bg-light">
                <label className="col-lg-12">{ texture.name }</label>
            <div className="col-lg-12 float-left">
            {texture.files.length === 0 &&
              <Dropzone
                multiple={false}
                accept={fileType}
                onDrop={ acceptedFiles => {
                            this.handleAcceptedFiles(acceptedFiles, texture)
                       }
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone">
                    <div
                      className="dz-message needsclick mt-4"
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <div className="mb-3">
                        <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                      </div>
                      <h4>Drop files here</h4>
                    </div>
                  </div>
                )}
              </Dropzone>
            }

              <div
                className="dropzone-previews"
                id="file-previews"
              >
                {texture.files.map((f, i) => {
                  return (
                    <Card
                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                      key={i + "-file"}
                    >
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            {
                                !this.state.advancedMode &&
                                <img
                                  data-dz-thumbnail=""
                                  height="80"
                                  className="avatar-sm rounded bg-light"
                                  alt={f.name}
                                  src={f.preview}
                                />
                            }
                          </Col>
                          <Col>
                            <p
                              className="text-muted font-weight-bold mb-0"
                            >
                              { texture.progress !== 100 && !texture.uploaded && !this.state.advancedMode ? 'Uploading ' + texture.progress + '%' : '' }
                              { texture.progress === 100 && !texture.uploaded && !this.state.advancedMode ? 'Processing... ' : '' }
                              { texture.uploaded || this.state.advancedMode ? f.name : ''}
                              { (texture.uploaded || (texture.files.length > 0 && this.state.advancedMode)) &&
                                <button
                                  type="button"
                                    onClick={() => this.handleRemovedFiles(texture)}
                                  className="close"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              }
                            </p>
                            {
                                texture.progress < 100 && !this.state.advancedMode &&
                                <Progress className="mt-2" style={{ height: "5px" }} color="success" value={texture.progress} ></Progress>
                            }

                          </Col>
                        </Row>
                      </div>
                    </Card>
                  );
                })}
              </div>

            </div>
            </div>
            </div>
        );
    }
    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    deleteFile(filename) {
        let sessionData = JSON.parse(sessionStorage.getItem('authUser'));
        let data = {
            token: sessionData.user_token,
            folder: this.state.activeFolder,
            filename: filename
        }
        console.log(filename);
        axios.post(apiLink('admin/deletefile'), data)
            .then(res => {
                console.log(res);
                if (res.data.success === true) {
                    toastr.success("File deleted", "");
                    this.initializeView(this.state.activeFolder);
                } else {
                    toastr.error("Error", "");
                }
            })
            .catch(err => { 
                console.log(err.response);
                toastr.error("Server error", "");
            });
    }

    changeFolder(id) {
        this.setState({ activeFolder: id });
        this.initializeView(id);
    }

    upload(texture) {
        
        if (this.state.advancedMode && texture !== this.state.thumbnail) {
            return;
        }
        
        const config = {
            onUploadProgress: progressEvent => {
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                texture.progress = percentCompleted;
                this.setState({
                    texture
                });
            }
        }
        texture.uploaded = false;
        let data = new FormData()
        data.append('file', texture.files[0])
        let sessionData = JSON.parse(sessionStorage.getItem('authUser'));
        data.append('token', sessionData.user_token);
        data.append('folder', this.state.activeFolder);

        axios.post(apiLink('admin/uploadfile'), data, config)
            .then(res => {
                console.log(res);
                if (res.data.success === true) {
                    texture.uploaded = true;
                    texture.id = res.data.data;
                    this.setState({
                        texture
                    });
                    toastr.success("Upload successful", "");
                    this.initializeView(this.state.activeFolder);
                }
            })
            .catch(err => { 
                console.log(err.response);
                toastr.error("Server error", "");
            });
    }

    render() {

        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                    <span className="font-size-22 mr-4">Folder</span>

                    <div className="btn-group">

                    <Button type="button" color="success" size="big" className={ 'waves-effect waves-light ' + (this.state.activeFolder === folderType.DOWNLOAD0 ? 'active' : '') } onClick={ () => this.changeFolder(folderType.DOWNLOAD0) } >
                        Moissy download
                    </Button>

                    <Button type="button" color="warning" size="big" className={ 'waves-effect waves-light ' + (this.state.activeFolder === folderType.UPLOAD0 ? 'active' : '') } onClick={ () => this.changeFolder(folderType.UPLOAD0) } >
                        Moissy upload
                    </Button>

                    </div>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        {/* <CardTitle className="mb-4">
                            <Button type="button" color="success" size="big" className="waves-effect waves-light float-right mb-4 ml-2" onClick={ null }>
                                <i className="bx bx-upload font-size-18 align-middle mr-2"></i>Dodaj Plik
                            </Button>
                        </CardTitle> */}
                        <div className="row">
                            { this.renderDropzone(this.state.file, true, '.jpg') }
                        </div>
                        <div className="table-responsive">
                            <table className="table table-centered table-nowrap mb-0">
                                <thead className="thead-light">
                                    <tr>
                                        <th>File name</th>
                                        <th>Date</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.files.map((file, key) =>
                                            <tr key={"_tr_" + key }>
 
                                                <td><strong>{ file.name }</strong></td>
                                                <td>{ file.date }</td>

                                                <td>
                                                    <div className="btn-group">
                                                    <Button type="button" color="primary" size="sm" className="waves-effect waves-light" onClick={ () => window.open(apiLink('uploads/' + this.state.activeFolder + '/' + file.name), '_blank') } >
                                                        <i className="bx bx-download font-size-14 align-middle"></i> Download
                                                    </Button>
                                                    <Button type="button" color="danger" size="sm" className="waves-effect waves-light" onClick={() => this.deleteFile(file.name) }>
                                                        <i className="bx bx-trash font-size-14 align-middle"></i> Delete
                                                    </Button>
                                                    
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </CardBody>
                </Card>
                <Modal
                    size="xl"
                  isOpen={this.state.toggleAddMaterial}
                    scrollable={true}
                >
                  <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">Add file</h5>
                    <button
                      type="button"
                      onClick={() =>
                        this.setState({ toggleAddMaterial: false })
                      }
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    {this.renderDropzone(this.state.file, true, '.jpg')}
                  </div>

                </Modal>
                {this.state.confirmDeletion ? (
                    <SweetAlert
                        title="Czy usunąć materiał?"
                        warning
                        showCancel
                        confirmBtnText="Usuń"
                        cancelBtnText="Anuluj"
                        confirmBtnBsStyle="danger"
                        cancelBtnBsStyle="success"
                        onConfirm={() => {
                            this.setState({confirmDeletion: false});
                            }
                        }
                        onCancel={() =>
                            this.setState({confirmDeletion: false})
                        }
                    >
                        Akcja jest nieodwracalna.
                    </SweetAlert>
                ) : null}
                

                <Modal size="sm" isOpen={this.state.isSaving} scrollable={true} centered >
                    <div className="modal-body mt-3 text-center">
                                <p><span class="spinner-border spinner-border-sm mr-4 " role="status" aria-hidden="true"></span>Saving...</p>
                    </div>
                </Modal>

                <Modal size="sm" isOpen={this.state.isLoading} scrollable={true} centered >
                    <div className="modal-body mt-3 text-center">
                                <p><span className="spinner-border spinner-border-sm mr-4 " role="status" aria-hidden="true"></span>Loading...</p>
                    </div>
                </Modal>
            </React.Fragment>
        );   
    }
}

export default withRouter(Materials);
