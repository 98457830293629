import React, { Component } from "react";
import { Container } from "reactstrap";

// Pages Components
import Materials from "./Materials";

//i18n
import { withNamespaces } from 'react-i18next';

class Dashboard extends Component {

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        <Materials />

                    </Container>
                </div>

            </React.Fragment>
        );
    }
}

export default withNamespaces()(Dashboard);
